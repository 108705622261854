import { render, staticRenderFns } from "./iDDPortal.vue?vue&type=template&id=a1344854&scoped=true&"
import script from "./iDDPortal.vue?vue&type=script&lang=js&"
export * from "./iDDPortal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1344854",
  null
  
)

export default component.exports