<style scoped lang="scss">
.companyChoose-box {

  /*height: 100%;*/
  /*box-sizing: border-box;*/
  .companyChoose-box-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    .box-title-icon {
      background: url('~@/static/projImg/comp-icon-act.svg') center center no-repeat;
      width: 45px;
      height: 45px;
      display: inline-block;
      /*background-color: #e6f0fe;*/
      /*border-radius: 50%;*/
      /*margin-bottom: 10px;*/
    }

    .box-title-text {
      color: #4e7ab6;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .companyChoose-box-content {
    margin: 10px 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /*height: calc(100% - 90px);*/
    overflow-y: auto;

    .box-content-item {
      width: 320px;
      height: 175px;
      box-sizing: border-box;
      background-color: #f9fcff;
      border-radius: 10px;
      border: 1px solid #eff6ff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 20px;

      .box-content-item-icon {
        background: url('~@/static/projImg/comp-icon.svg') center center no-repeat;
        width: 45px;
        height: 45px;
        display: inline-block;
        background-color: #e6f0fe;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      .box-content-item-name {
        color: #4a4a4a;
        font-size: 14px;
        font-weight: bold;
      }

      &:hover {
        border: 1px solid #5a9cf8;

        .box-content-item-icon {
          background: url('~@/static/projImg/comp-icon-act.svg') center center no-repeat;
        }

        .box-content-item-name {
          color: #5a9cf8;
        }
      }
    }
  }
}

.ssss {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: calc(100% - 90px);
  overflow-y: auto;
}
</style>
<template>
  <div v-loading.fullscreen.lock="loading">
    <div class=" companyChoose-box">
      <el-dialog :visible="showDialog" @close="close" width="80%" v-if="showCompChoose">
        <div class="companyChoose-box">
          <div class="companyChoose-box-title">
            <div class="box-title-icon"></div>
            <!-- 请选择登陆企业 -->
            <div class="box-title-text">
              {{ $t('tips.selectCompanyLogin') }}
            </div>
          </div>
          <div id="box-content" class="companyChoose-box-content">
            <li class="box-content-item" v-for="(table, index) in tableList" :key="index" @click="chooseOne(table)">
              <div class="box-content-item-icon"></div>
              <div class="box-content-item-name">
                {{ table.locationName | filterUndefinedNull }}
              </div>
            </li>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    showCompChoose: {
      type: Boolean,
      default: true
    }
  },
  components: {
  },
  data() {
    return {
      loading: false,
      layerLoading: false,
      checked: false,
      showDialog: true,
      tableList: [
        // {"id":4,"entEnName":"init_supplier","entCnName":"初始化测试供应商","entCountry":"中国","entCode":"ENT2021042600004","archive":false,"auditStatus":"0","createBy":"xxx","createTime":"2021-04-27 15:14:53","updateBy":"xxx","updateTime":"2021-04-27 15:14:59","enable":true}
      ],
      pdfUrl: '',
      contractId: '',
      isOneComp: false,
    }
  },
  computed: {
    // showDialog(){
    //     return this.show
    // }
  },
  watch: {
    // show (val) {
    //   this.showDialog = val
    // }
  },
  methods: {
    closePdf() {
      this.pdfUrl = ''
    },
    //确认签署
    sureSign() {
      let _this = this
      if (this.layerLoading) return
      this.layerLoading = true
      _this.$http
        .post(`/kj-contract/contractInfo/signContract?id=${this.contractId}`)
        .then(
          function (data) {
            // _this.$router.push('/home');
            // _this.closePdf()
            _this.layerLoading = false
            // 签署成功
            _this.$message({
              message: _this.$t('common.signedSuccessfully'),
              type: 'success',
            })
            _this.chooseFinal(_this.currentComp)
          },
          function (response) {
            _this.layerLoading = false
          }
        )
    },
    //获取授权书
    async getAuthAgm() {
      let _this = this
      let result = await _this.$http
        .post(
          `/kj-idd/home/queryAuthAgreementSignStatus?enterpriseId=${this.currentComp.id}`
        )
        .then(
          function (data) {
            let url = data.data.data || false
            if (!url) {
              return false
            } else {
              return true
            }
          },
          function (response) {
            return true
          }
        )
      return result
    },
    async close() {
      this.show = false
      // this.$emit('close')
      // this.$router.replace('/login')
      let res = await this.$sso.logout()
      if (res.code === '200') {
        localStorage.clear()
        sessionStorage.clear()
        this.$router.replace('/login')
      }
    },
    saveStatus(table) {
      this.$emit('sure', table)
    },
    //选择一个企业
    async chooseOne(table) {
      this.chooseFinal(table)
    },
    //最终选择企业进入
    async chooseFinal(table) {
      // 保存当前企业的钱包类型
      const walletType = JSON.parse(table.walletType.replaceAll('\\', '')).dictKey
      sessionStorage.setItem('walletType', walletType || 'custody')
      //判断该企业有无菜单，无菜单没有权限
      let curLoginName = sessionStorage.getItem('curLoginName')
      const powerFlag = await this.$API.enterprise.getMenuByEnterpriseId({ "sysChannel": "kj_my", enterpriseId: table.id, loginName: curLoginName })
      if (!powerFlag) return

      let _this = this
      sessionStorage.setItem('enterpriseId', table.id)
      //先获取企业的产品信息
      const queryObj = await _this.$http.get(
        // `/kj-kyc/product/getByEnterpriseId?enterpriseId=${table.id}`
        `/kj-kyc/product/listByEnterpriseId?enterpriseId=${table.id}`
      )
      let productTypeArr = queryObj.data.data || []
      //国际结算特殊处理
      let calcCompany = productTypeArr.filter(item => {
        let productType = this.filterJSONString(item.productType)
        return productType == 'PURCHASE_PAYMENT'
      })
      if (calcCompany && calcCompany.length > 0) {
        //存在国际结算产品
        if (calcCompany.length == productTypeArr.length) {
          //有且只有一个国际计算产品
          sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', true)
        } else {
          sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', false)
        }
      } else {
        sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', false)
      }
      let productTypeObj = productTypeArr[0] || {}
      let productType = productTypeObj.productType ? JSON.parse(productTypeObj.productType).dictParam : ''
      sessionStorage.setItem('enterpriseProductType', productType)
      let status = JSON.parse(table.status).dictParam //ENDURANCE  审核通过
      _this.$http.get(`/kj-web/sys/setOrgId?orgId=${table.id}`).then(
        async data => {
          var code = data.data.code

          if (code == '200') {
            // _this.$store.state.currentCompany = table
            if (status == 'ENDURANCE') {
              //审核通过
              // const query = this.$route.query
              _this.$router.push({ path: table.toPage || '/home' })
              if (table.jumpFlag) {
                _this.$emit('getProductInfo')
              }
            } else if (status == 'INIT' && productType == 'E_FINANCE') {
              //待完善 产品类型为电商融资
              _this.$router.push({
                path: '/childEkycPortal/efinKyc',
                query: { id: table.id, status: status },
              })
            } else if (status == 'BACK' && productType == 'E_FINANCE') {
              //审核退回 产品类型为电商融资
              _this.$router.push({
                path: '/childEkycPortal/efinKyc',
                query: { id: table.id, status: status },
              })
            } else if (status == 'SUBMIT' && productType == 'E_FINANCE') {
              //建档审核中 产品类型为电商融资
              _this.$router.push({
                path: '/childEkycPortal/efinKyc',
                query: { id: table.id, status: status },
              })
            } else if (status == 'INIT' && productType == 'IDD') {
              //待完善 产品类型为IDD
              _this.$router.push({
                path: '/kycFileCN',
                query: { id: table.id, status: status },
              })
            } else if (status == 'BACK' && productType == 'IDD') {
              //审核退回 产品类型为IDD
              _this.$router.push({
                path: '/kycFileCN',
                query: { id: table.id, status: status },
              })
            } else if (status == 'SUBMIT' && productType == 'IDD') {
              //建档审核中 产品类型为IDD
              _this.$router.push({
                path: '/kycFileCN',
                query: { id: table.id, status: status },
              })
            } else {
              _this.$alert(_this.$t('login.onboardingStatusAbnormal'), _this.$t('login.tips'),
                { confirmButtonText: _this.$t('login.confirm'), })
            }
          } else {
            // $.fn.mAlert('body',{tips:data.body.msg});
            _this.$alert(data.body.message, _this.$t('login.tips'), {
              confirmButtonText: _this.$t('login.confirm'),
            })
          }
          _this.loading = false
          if (this.$parent.$options.name === 'home') {
            this.$parent.shopName = table.locationName
            if (table.jumpFlag) {
              // 激活二级菜单的主页
              sessionStorage.setItem('curMenuIndex', '395')
            } else {
              sessionStorage.setItem('curMenuIndex', '')
            }
            this.$parent.getMenu()
            //初始化
            this.$parent.iddSupFlag = false
            this.$parent.iddTimeShow = false
          }
        },
        function (response) {
          _this.loading = false
          // $.fn.mAlert('body',{tips:response});
          _this.$alert(response, _this.$t('login.tips'), {
            confirmButtonText: _this.$t('login.confirm'),
          })
        }
      )
    },
    //获取企业
    getCompList(alredySelectComp = false) {
      this.loading = true
      if (alredySelectComp) {
        this.chooseOne(alredySelectComp)
        return
      }
      let _this = this
      // _this.$http.post('/kj-web/sys/getEnterpriseVO').then(
      _this.$http.post('/kj-sys/kjEnterprise/getEnterpriseByType', { productType: ['IDD', 'DOC_EXCHANGE', 'PURCHASE_PAYMENT', 'E_FINANCE', 'MARKET', 'LDA', 'ABT'] }).then(
        function (data) {
          var code = data.data.code
          if (code == '200') {

            // _this.$router.push('/login');
            let tl = data.data.data || []
            const enterpriseRole = tl[0]?.enterpriseRole ? JSON.parse(tl[0]?.enterpriseRole)?.dictParam : null
            sessionStorage.setItem('enterpriseRole', enterpriseRole)
            // 内管端有且只有一个企业
            // tl.map(item=>{
            //     this.userName = item.entCnName || ''
            // })
            if (tl.length > 1) {
              _this.tableList = tl
            } else {
              if (tl.length == 1) {
                _this.isOneComp = true
                //只有一个企业
                // _this.$router.push('/home')
                _this.tableList = tl
                const walletType = JSON.parse(tl[0].walletType.replaceAll('\\', '')).dictKey
                sessionStorage.setItem('walletType', walletType || 'custody')

                //自动选择逻辑去除-------2022.08.01
                // _this.chooseOne(tl[0])
              } else {
                _this.$alert(
                  'You currently have no business, please contact the operation personnel for assistance in handling',//您暂无企业，请联系运营人员协助处理，谢谢
                  'Tips',
                  {
                    confirmButtonText: 'Confirm',
                    callback: action => {
                      _this.close()
                    },
                  }
                )
              }
            }
          } else {
            // $.fn.mAlert('body',{tips:data.body.msg});
            _this.$alert(data.body.message, 'Tips', {
              confirmButtonText: 'Confirm',
            })
          }
          _this.loading = false
        },
        function (response) {
          _this.loading = false
          // $.fn.mAlert('body',{tips:response});
          _this.$alert(response, 'Tips', {
            confirmButtonText: 'Confirm',
          })
        }
      )
    },
  },
  mounted() {
    this.$nextTick(() => {
      let contentH = this.getSrcollHeight() * 0.6 - 100 - 90
      $('#box-content').height(contentH)
    })
    this.getCompList()
  },
}
</script>
