import { render, staticRenderFns } from "./abtPortal.vue?vue&type=template&id=30a4819c&scoped=true&"
import script from "./abtPortal.vue?vue&type=script&lang=js&"
export * from "./abtPortal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a4819c",
  null
  
)

export default component.exports