import Vue from 'vue'
const vm = new Vue()
import router from '@/routes.js'
const api = {}
/**
 * 查询产品信息
 *
 */
 api.getProductByEnterpriseId =  function(id) {
     //取建档的开通产品的信息可以用这个接口
    let url = `/kj-kyc/product/getByEnterpriseId?enterpriseId=${id}`
    return vm.$http.get(url)
}
/**
 * 查询企业信息
 *
 */
api.getByEnterpriseId =  function(id) {
    let url = `/kj-kyc/enterprise/getByEnterpriseId?enterpriseId=${id}`
    return vm.$http.get(url)
}
/**
 * 查询国家数据
 *
 */
 api.getCountry =  function(queObj) {
    let url = `/kj-kyc/country/list`
    return vm.$http.post(url,queObj)
}
/**
 * 上传文件
 *
 */
 api.uploadFile = function(enterpriseId,catgId,queObj) {
    let url = `/kj-media/media/opera/do/uploadMediaFile?modelCode=MKJ01ENT&catgId=${catgId}&busiKey=${enterpriseId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
/**
 * 删除已上传文件
 *
 */
 api.deleteFileByCatgId = function(enterpriseId,catgId,queObj) {
    let url = `/kj-media/media/opera/do/deleteFileByCatgId?modelCode=MKJ01ENT&catgId=${catgId}&busiKey=${enterpriseId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
//判断该企业的菜单是否有权限
api.getMenuByEnterpriseId = async function(queObj,needBack = false) {
    let url = `/kj-sys/menu/getEnterpriseMenuList`
    let powerFlag = await vm.$http.post(url,queObj).then(data=>{
        let tl = data.data.data || [];
        if(tl && tl.length > 0){
            return true
        }else{
            return false
        }
    },err=>{
        return false
    })
    if(!powerFlag){
        // window.vm.$message('暂无权限，请联系管理员');
        window.vm.$message.error(window.vm.$t('login.noPermission'));
        if(needBack){
            window.vm.$sso.logout()
            router.replace('/login')
        }
        
    }
    return powerFlag
}
export default api