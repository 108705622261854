import Vue from 'vue'
//过滤
Vue.filter('filterDic', function (value) {
    if(value){
        if(value.indexOf('{')>-1)
        return JSON.parse(value).displayName
    }
});
Vue.filter('filterDate',function(value){
    if(value){
        return value.substring(0,10)
    }
})
Vue.filter("money",function(number,places, symbol, thousand, decimal){
    number = number || 0;
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var negative = number < 0 ? "-" : "";
    var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "";
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
})
Vue.filter('filterUndefinedNull', function (value) {
    if(value === undefined || value === null || value === ''){
        return '-'
    }else {
        return value
    }
});