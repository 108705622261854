<template>
  <div id="efinancePortal" class="micor-app-box"></div>
</template>

<script>
const getActiveRule = hash => location => location.hash.startsWith(hash)
import { loadMicroApp } from 'qiankun'

export default {
  name: 'efinancePortal',
  data() {
    return {
      microApp: null,
    }
  },
  watch: {
    $route: function(val, oldVal) {
      this.microApp.update({ routeChange: { to: val, from: oldVal } })
    },
  },
  mounted() {
    this.$store.commit('updateProduct', 'efinance')
    let currentRoute = this.$route.fullPath
    this.microApp = loadMicroApp({
      name: 'efinancePortal', //应用名字
      entry:
        process.env.NODE_ENV === 'production'
          ? '/efinancePortal/'
          : '//localhost:3001/', //默认会加载这个HTML 动态加载资源 （必须支持跨域）
      // fetch
      container: '#efinancePortal', //容器名
      activeRule: getActiveRule('#/child-efinancePortal'), //激活路径hash模式
      props: {
        fatherRouter: this.$router,
        currentRoute,
      },
    })
  },
  destroyed() {
    this.microApp.unmount()
  },
}
</script>

<style scoped>
</style>
