<template>
    <div id="ekycPortal" class="micor-app-box">

    </div>
</template>

<script>
    const getActiveRule = (hash) => (location) => location.hash.startsWith(hash);
    import { loadMicroApp  } from 'qiankun';
    export default {
        name: "ekycPortal",
        data(){
            return{
                microApp:null
            }
        },
        watch:{
            "$route": function(val,oldVal){
                this.microApp.update({routeChange:{to:val,from:oldVal}});
            }
        },
        methods:{},
        mounted() {
            let currentRoute = this.$route.fullPath
            let sourceListObj = this.$store.state.resourceObj
            this.microApp = loadMicroApp({
                name:'ekycPortal',//应用名字
                entry:process.env.NODE_ENV === 'production' ? '/ekycPortal/' :'//localhost:9393',//默认会加载这个HTML 动态加载资源 （必须支持跨域）
                // fetch
                container:'#ekycPortal', //容器名
                activeRule:getActiveRule('#/childEkycPortal'),  //激活路径
                props:{
                    fatherRouter:this.$router,//父级路由
                    currentRoute,//当前路由
                    sourceListObj,//资源权限
                }
            },);

        },
        destroyed(){
            this.microApp.unmount();
        },
    }
</script>

<style scoped>

</style>