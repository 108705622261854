<style lang="scss" scoped>
.login {
  height: 100%;
  background-color: #f7fafd;
  position: relative;
  background-size: 100% 100%;
}
.login-form {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  ::v-deep .el-input__suffix {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.logo-box {
  margin: 55px 0px 55px 205px;
}
.logo {
  width: 48px;
  height: auto;
  /*background-color: gray;*/
  margin: 0 auto;
}
.logo-lianyirong {
  width: 105px;
  height: auto;
  margin-right: 10px;
}
.label {
  color: #fff;
}
.login-bottom-text {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 50px;
  color: #ffffff;
  background-color: #455771;
  height: 50px;
}
.err-text {
  height: 15px;
  color: #f56c6c;
  font-size: 12px;
  margin-bottom: 22px;
}
::v-deep .el-loading-mask {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  .el-loading-spinner {
    margin-top: -15px;
    .circular {
      height: 22px;
      width: 22px;
    }
  }
}
.el-button {
  padding: 5px 20px;
  border: 1px solid rgba(90, 156, 248, 0.5);
}
.el-button:hover {
  background-image: linear-gradient(rgb(90, 156, 248), rgb(144, 190, 253));
}
::v-deep .el-form-item__content {
  line-height: 10px;
}
::v-deep .el-form-item__label {
  margin-bottom: 2px;
}
.enlarge {
  width: 205px;
}
.term-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .term-row2-wrapper {
    height: 75%;
  }
  ::v-deep .el-tabs {
    width: 80%;
    margin: 0px auto;
    .el-tabs__nav-scroll {
      overflow: auto;
    }
    .el-tabs__nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .el-tabs__active-bar {
      display: none;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .el-tabs__item {
      font-size: 20px;
      font-weight: bold;
      color: #99a3b1;
    }
    .el-tabs__item.is-active {
      color: #4e78b1;
    }
  }
}
</style>

<template>
  <div class="login">
    <div class="term-wrapper">
      <div class="logo-box">
        <img
          src="@/static/projImg/logo-lianyirong.svg"
          alt=""
          class="logo-lianyirong"
        />
        <img
          :src="logo"
          alt=""
          class="logo"
        />
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="Platform Service Agreement"
          name="first"
        ></el-tab-pane>
        <!-- <el-tab-pane
          label="Doc.Ex Terms & Conditions"
          name="second"
        ></el-tab-pane>
        <el-tab-pane label="SCF Terms & Conditions" name="third"></el-tab-pane> -->
      </el-tabs>
      <preview-pdf :url="url" class="term-row2-wrapper" />
    </div>
    <div class="login-bottom-text">
      © 2022 Linklogis International Company Limited
    </div>
  </div>
</template>

<script>
import PreviewPdf from '@/components/previewPdf.vue'
export default {
  name: 'loginSSO',
  components: {
    PreviewPdf,
  },
  data() {
    return {
      logo: require('@/static/projImg/logo.svg'),
      activeName: 'first',
      termMap: {
        first: require('@/assets/NDA_LLS_(Project Guardian).pdf'),
        second: require('@/assets/docex_service_schedule.pdf'),
        third: require('@/assets/scf_service_schedule.pdf'),
      },
      pdfUrl: ''
    }
  },
  computed: {
    url() {
      const res = this.termMap[this.activeName]
      return res
    },
  },
  async created() {
    const { data } = await this.$API.system.getNoAuthAllWhiteLabel()
    if(data.data?.termsCondition) {
      // base64转二进制
      const termsCondition = Buffer.from(data.data.termsCondition, 'base64');
      const uInt8Array = new Uint8Array(termsCondition)
      // 转字节
      const blob = new Blob([uInt8Array], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      this.termMap.first = pdfUrl
    }
  },
  async mounted() {},

  // mounted:function(){
  //     sessionStorage.clear()
  //     this.refreshimg()
  //     this.ruleForm.pass = this.$route.query.userName || ''
  // },
  methods: {},
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
}
</script>
