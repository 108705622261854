<template>
    <div id="custodyPortal" class="micor-app-box">
      
    </div>
</template>

<script>
    const getActiveRule = (hash) => (location) => location.hash.startsWith(hash);
    import { loadMicroApp  } from 'qiankun';
    export default {
        name: "custodyPortal",
        data(){
            return{
                microApp:null
            }
        },
        watch:{
            "$route": function(val,oldVal){
                this.microApp.update({routeChange:{to:val,from:oldVal}});
            }
        },
        methods:{},
        mounted() {
            let currentRoute = this.$route.fullPath
            this.microApp = loadMicroApp({
                name:'custodyPortal',//应用名字
                entry:process.env.NODE_ENV === 'production' ? '/custodyPortal/' :'//localhost:8990',//默认会加载这个HTML 动态加载资源 （必须支持跨域）
                // fetch
                container:'#custodyPortal', //容器名
                activeRule:getActiveRule('#/childCustodyPortal'),  //激活路径
                props:{
                    fatherRouter:this.$router,
                    currentRoute
                }
            },);

        },
        destroyed(){
            this.microApp.unmount();
        },
    }
</script>

<style scoped>

</style>