<template>
  <div class="preview-wrapper">
    <div class="preview-item-wrapper" v-loading="loading">
      <div class="home_wrap" @scroll="handleScroll" id="home_wrap">
        <div
          v-for="page in pages"
          :key="page"
          :style="{ width: pdf_div_width, margin: '0 auto' }"
          class="source-agreement-canvas"
          :id="'source-agreement-canvas' + page"
        >
          <canvas
            :id="'the_canvas' + page"
            :key="page"
            class="source-item-wrapper"
          />
        </div>
      </div>
    </div>
    <div class="preview-toolbar-wrapper" v-if="false">
      <div class="preview-toolbar-left">
        <i class="el-icon-top" @click="handlePre"></i
        ><i class="el-icon-bottom" @click="handleNext"></i>
      </div>
      <div>{{ this.pageNum }}|{{ this.pages }}</div>
    </div>
  </div>
</template>

<script>
import * as PdfJs from 'pdfjs-dist/build/pdf.js'
import * as _ from 'lodash'
import { TextLayerBuilder, EventBus } from 'pdfjs-dist/web/pdf_viewer.js'
import * as pdfUiUtils from 'pdfjs-dist/lib/web/ui_utils.js'
import 'pdfjs-dist/web/pdf_viewer.css'
export default {
  name: 'transactionDetails',
  props: ['url'],
  components: {},
  data() {
    return {
      comment: null,

      pdf_scale: 1.0, //pdf放大系数
      pages: 0,
      pdf_div_width: '',
      pdf_src: null,
      pageRendering: false,
      pageNumPending: null,
      pageNum: 1,
      max: 1,
      pdfDoc: null,
      loading: false,
      ctx: null,
      canvas: null,
      loadingTask: undefined,
      pdfViewer: undefined,
      eventBus: undefined,
      num: undefined,
    }
  },
  watch: {
    url: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.reset()
          return
        }
        if (newVal !== oldVal) {
          this.reset()
          this.pdf_src = newVal
          this.loadFile(this.pdf_src, true)
        }
      },
      immediate: true,
    },
  },
  computed: {
    // ...mapState('docExIndex', ['docInfo']),
    // ...mapState('createDoc', ['actionType']),
  },
  methods: {
    handleScroll() {
      // const res = getVisibleElements(container, elements, true);
      const container = document.querySelector('.home_wrap')
      const elements = document.querySelectorAll('.source-agreement-canvas')
      const toPdfDoms = Array.from(elements).map(item => {
        const res = { div: item }
        return res
      })
      const res = pdfUiUtils.getVisibleElements(container, toPdfDoms, true)
      const visibleElements = res.views
          if(!visibleElements || !visibleElements[0]){
              return
          }
      const maxElement = visibleElements[0].view.div.id
      const id = maxElement.slice(23)
      if (this.pageNum !== id) {
        this.pageNum = id
      }
    },
    handleNext() {
      const pageNum = Number(this.pageNum)
      const res =
        this.pages >= pageNum + 1
          ? `#source-agreement-canvas${pageNum + 1}`
          : `#source-agreement-canvas${this.pageNum}`
      if (pageNum + 1 <= this.pages) {
        this.pageNum = pageNum + 1
      }
      const element = document.querySelector(res)
      pdfUiUtils.scrollIntoView(element, { top: 0, left: 0 })
    },
    handlePre() {
      const pageNum = Number(this.pageNum)
      const res =
        pageNum - 1 >= 1
          ? `#source-agreement-canvas${pageNum - 1}`
          : `#source-agreement-canvas${this.pageNum}`
      if (pageNum - 1 >= 1) {
        this.pageNum = pageNum - 1
      }
      const element = document.querySelector(res)
      pdfUiUtils.scrollIntoView(element, { top: 0, left: 0 })
    },
    async reset() {
      if (this.loadingTask && !this.loadingTask.destroyed) {
        this.loadingTask.destroy()
        this.$emit('destroy')
      }
      this.pdf_scale = 1.0
      this.pages = 0
      this.pdf_div_width = 0
      this.pdf_src = ''
      this.pageRendering = false
      this.pageNumPending = null
      this.pageNum = 1
      if (this.ctx) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.canvas = null
        this.ctx = null
      }
    },
    loadFile(url, initial) {
      this.loading = true
      PdfJs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry.js')
      this.loadingTask = PdfJs.getDocument(url)
      this.loadingTask.promise
        .then(pdf => {
          if (this.pdfDoc) {
            this.pdfDoc.destroy()
          }
          this.pdfDoc = pdf
          this.pages = this.pdfDoc.numPages
          this.$nextTick(() => {
            this.renderPage(this.pageNum, initial) // 表示渲染第 1 页
          })
        })
        .catch(e => {
          console.log('e:', e)
        })
        .finally(() => {})
    },
    renderPage(num, initial) {
      //渲染pdf页
      this.loading = true
      this.pdfDoc.getPage(num).then(page => {
        let canvas = document.getElementById(`the_canvas${num}`)
        this.canvas = canvas

        const res = document.querySelector('.home_wrap')
        const pdf = res.getBoundingClientRect()
        let width = pdf.width
        if (!canvas) {
          return
        }
        let ctx = canvas.getContext('2d')
        this.ctx = ctx
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        let dpr = window.devicePixelRatio || 1
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1
        let ratio = 1
        if (pdf && initial) {
          this.pdf_scale = width / page.view[2]
        }
        let viewport = page.getViewport({ scale: this.pdf_scale })
        if (!initial) {
          width = viewport.width
        }
        if (this.pdf_scale > 1) {
          this.max = this.pdf_scale
        }

        canvas.width = width * ratio
        canvas.height = viewport.height * ratio

        canvas.style.width = `${width * ratio}px`

        this.pdf_div_width = `${width * ratio}px`

        canvas.style.height = `${viewport.height}px`

        ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        }
        const renderTask = page.render(renderContext)
        renderTask.promise.then(async () => {
          const textContent = await page.getTextContent()
          const textLayerDiv = document.createElement('div')
          textLayerDiv.setAttribute('class', 'textLayer')
          textLayerDiv.setAttribute(
            'style',
            `width: ${viewport.width}px; margin: 0 auto;`
          )
          textLayerDiv.style.position = 'absolute'
          textLayerDiv.style.width = `${viewport.width}px`
          textLayerDiv.style.height = `${viewport.height}px`
          const pageDiv = document.getElementById(
            `source-agreement-canvas${num}`
          )
          // 将文本图层div添加至每页pdf的div中
          pageDiv.appendChild(textLayerDiv)
          // 创建新的TextLayerBuilder实例
          var textLayer = new TextLayerBuilder({
            textLayerDiv: textLayerDiv,
            pageIndex: page.pageIndex,
            viewport: viewport,
            eventBus: this.eventBus,
          })

          textLayer.setTextContent(textContent)

          textLayer.render()
          this.loading = false
          if (this.pages > num) {
            this.renderPage(num + 1)
          }
        })
        // const renderTask = page.render(renderContext)
        // renderTask.promise.then(() => {
        //   this.loading = false
        //   if (this.pageNumPending !== null) {
        //     this.renderPage(this.pageNumPending)
        //     this.pageNumPending = null
        //   }
        // })
      })
    },
    handleResize() {
      this.renderPage(1, true)
      this.pageNum = this.pageNum - 1
      this.num = setTimeout(() => this.handleNext())
    },
  },
  mounted() {
    window.addEventListener('resize', _.debounce(this.handleResize, 500))
    const eventBus = new EventBus()
    this.eventBus = eventBus
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    clearTimeout(this.num)
  },
}
</script>
<style lang="scss" scoped>
.preview-wrapper {
  border-radius: 8px;
  width: 100%;
  height: 80%;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  .source-agreement-header {
    position: absolute;
    top: 10px;
    left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 10px);
    .source-icon {
      width: 14px;
      height: 14px;
      color: white;
      margin: 0px 10px;
    }
    .source-agreement-right {
      width: 35%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;
    }
    .source-agreement-left {
      color: #fff;
    }
  }
  .preview-item-wrapper {
    width: 90%;
    height: 90%;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    .home_wrap {
      width: 100%;
      height: 100%;
      overflow: auto;
      position: relative;
      .pdf_down {
        display: flex;
        z-index: 20;
        right: 26px;
        bottom: 7%;
        .pdf_set_left {
          width: 30px;
          height: 40px;
          color: #408fff;
          font-size: 11px;
          padding-top: 25px;
          text-align: center;
          margin-right: 5px;
          cursor: pointer;
        }
        .pdf_set_middle {
          width: 30px;
          height: 40px;
          color: #408fff;
          font-size: 11px;
          padding-top: 25px;
          text-align: center;
          margin-right: 5px;
          cursor: pointer;
        }
      }
      .source-agreement-canvas {
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }
  }
  .preview-toolbar-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90vw;
    padding: 5px 0px;
    background: #fff;
    .preview-toolbar-left {
      margin: 0px 0px 0px 10px;
    }
  }
}
</style>
