import VueRouter from 'vue-router'
import { filterDictParam } from '@/common/util.js'

import Vue from 'vue'
Vue.use(VueRouter)
const vm = new Vue()
import loginSSO from './views/loginSSO.vue'
import home from './views/home.vue'
//根页面
import global from './views/global.vue'

let routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    component: loginSSO,
    name: '登录',
  },
  {
    path: '/terms',
    component: require('@/views/terms.vue').default,
  },
  {
    path: '/kycFileCN',
    component: require('@/views/kycFile/kycFileCN.vue').default,
    name: 'kyc建档',
  },
  {
    path: '/childEkycPortal/*',
    name: 'ekycPortal',
    component: require('@/views/ekycPortal/ekycPortal.vue').default,
  },
  {
    path: '/companyChoose',
    component: require('@/views/companyChooseAlert/companyChooseAlert.vue')
      .default,
    name: '选择企业',
  },
  {
    path: '/logoutPage',
    component: require('@/views/logoutPage.vue').default,
    name: '退出登录',
  },

  {
    path: '/home',
    component: home,
    name: '主页',
    children: [
      // {
      //   path: '/',
      //   component: global,
      // },
      {
        path: '/bigHome',
        component: global,
        name: '大首页',
      },
      {
        path: '/child-iDDPortal/*',
        name: 'iDDPortal',
        component: require('@/views/iDDPortal/iDDPortal.vue').default,
      },
      {
        path: '/childUserPt/*',
        name: 'cbfUserPortal',
        component: require('@/views/cbfUserPortal/cbfUserPortal.vue').default,
      },
      {
        path: '/childiDDUserPortal/*',
        name: 'iDDUserPortal',
        component: require('@/views/iDDUserPortal/iDDUserPortal.vue').default,
      },
      {
        path: '/childDocExchange/*',
        name: 'docPortal',
        component: require('@/views/docPortal/docPortal.vue').default,
      },
      {
        path: '/childCustodyPortal/*',
        name: 'custodyPortal',
        component: require('@/views/custodyPortal/custodyPortal.vue').default,
      },
      {
        path: '/childScbPortal/*',
        name: 'scbPortal',
        component: require('@/views/scbPortal/scbPortal.vue').default,
      },
      {
        path: '/child-efinancePortal/*',
        name: 'eFinancePortal',
        component: require('@/views/efinancePortal/efinancePortal.vue').default,
      },
      {
        path: '/child-marketplacePortal/*',
        name: 'marketplacePortal',
        component: require('@/views/marketplacePortal/marketplacePortal.vue')
          .default,
      },
      {
        path: '/childLdaPortal/*',
        name: 'ldaPortal',
        component: require('@/views/ldaPortal/ldaPortal.vue').default,
      },
      {
        path: '/childAbtPortal/*',
        name: 'abtPortal',
        component: require('@/views/abtPortal/abtPortal.vue').default,
      },
      // {
      //   path: '/childEkycPortal/*',
      //   name: 'ekycPortal',
      //   component: require('@/views/ekycPortal/ekycPortal.vue').default,
      // },
      // {
      //     path: '/abdSpy/*',
      //     name: 'abdSpy',
      //     component: require('@/views/abdSpy/abdSpy.vue').default,
      // },
      // {
      //     name: '前端代码自动生成',
      //     path:"/webAuto",
      //     component:webAuto,
      // }
    ],
  },
]

const router = new VueRouter({
  // mode:'history',
  routes,
})
router.beforeEach(async (to, from, next) => {
  if (to.path == '/childDocExchange/transactionDetails') {
    next()
  }

  if (from.path == '/login' && from.query.isReFlag == 1) {
    let { refer, lang, isReFlag, id } = from.query
    next({
      path: '/childDocExchange/transactionDetails',
      query: { refer, lang, isReFlag, id },
    })
  }
  // LDA菜单包含关键字childCustodyPortal,childLdaPortal 需要判断是否完成KYC
  else if (
    (to.path.indexOf('childCustodyPortal') > -1 ||
      to.path.indexOf('childLdaPortal') > -1 ||
      to.path.indexOf('childAbtPortal') > -1) &&
    to.path.indexOf('completeKYC') == -1 &&
    sessionStorage.getItem('enterpriseRole') != 'ISSUER'
  ) {
    const enterpriseId = sessionStorage.getItem('enterpriseId')
    if (!enterpriseId) {
      await vm.$sso.logout()
      next('/login')
    } else {
      const {
        data,
      } = await vm.$http.get(
        '/kj-custody/custodyChainWallet/getSimpleWalletByEnterpriseId',
        { params: { enterpriseId } }
      ).catch(()=>{
        next('/login')
      })

      const kycStatus = filterDictParam(data?.data?.kycStatus)
      // store.commit('setKycStatus', filterDictParam(kycStatus))
      if (kycStatus && kycStatus !== 'ONCHAIN') {
        next({
          path: '/childAbtPortal/completeKYC',
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})
export default router
