<style lang="scss" scoped>
.login {
  height: 100%;
  background: url('../static/projectImg/login/login_bgc.jpg') no-repeat;
  position: relative;
  background-size: 100% 100%;
}

.login-form {
  width: 285px;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  ::v-deep .el-input__suffix {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logo-box {
  text-align: center;
  // padding: 16px 0;
}

.logo {
  width: 48px;
  height: auto;
  /*background-color: gray;*/
  margin: 0 auto;
  margin-bottom: 50px;
}
.loginLogo{
  width: 40px;
  height: 40px;
}
.label {
  color: #fff;
}

.verification {
  position: absolute;
  right: 0px;
  // bottom: 4px;
  width: 68px;
  height: 32px;
  border-left: 1px solid #eee;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #00a0e4;
}

.verification-sent {
  background-color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  height: 30px;
  // bottom: 5px;
  font-size: 12px;
  width: 80px;
  border-left: 1px solid #e2e2e2;
  box-sizing: border-box;
}

.login-btn {
  width: 285px;
  height: 32px;
  font-size: 14px;
  color: #ffffff;
  background-image: linear-gradient(rgb(90, 156, 248), rgb(144, 190, 253));
  border-radius: 16px;
  cursor: pointer;
  margin-bottom: 40px;
}

.logo-box-text {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}

.login-text-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-forget {
  color: #ffffff;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.login-bottom-text {
  position: fixed;
  bottom: 6%;
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #ffffff;

  .el-link {
    font-size: 12px;
    color: #fff;
    vertical-align: text-top;

    &:hover {
      color: #409eff;
    }
  }
}

.err-text {
  height: 15px;
  color: #f56c6c;
  font-size: 12px;
  margin-bottom: 22px;
}

.agree-consent {
  margin-bottom: 22px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #fff;

  .agreeCheckBox {
    margin-top: -17px;
  }

  .my-agree {
    margin-left: 5px;
    margin-bottom: -13px;

    .agreeLink1 {
      text-decoration-line: underline;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      margin-left: 117px;
      margin-top: -35px;
    }

    .agreeLink2 {
      text-decoration-line: underline;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      margin-top: -32px;
    }

    .agreeLink3 {
      text-decoration-line: underline;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      margin-left: 213px;
      margin-top: -35px;
    }
  }
}

::v-deep .el-loading-mask {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  .el-loading-spinner {
    margin-top: -15px;

    .circular {
      height: 22px;
      width: 22px;
    }
  }
}

.el-button {
  padding: 5px 20px;
  border: 1px solid rgba(90, 156, 248, 0.5);
}

.el-button:hover {
  background-image: linear-gradient(rgb(90, 156, 248), rgb(144, 190, 253));
}

::v-deep .el-form-item__content {
  line-height: 10px;
}

::v-deep .el-form-item__label {
  margin-bottom: 2px;
}

.enlarge {
  width: 205px;
}
</style>

<template>
  <div class="login">
    <div class="login-form">
      <div class="logo-box" v-if="!forgetShow">
        <!-- <img :src="logo" alt="" :class="{ logo: true, enlarge: customer === 'volofin' }" /> -->
        <svg class="loginLogo" />
      </div>
      <template v-if="!forgetShow">
        <template v-if="!reSetPwd">
          <!-- <div class="logo-box-text">Log In</div> -->
          <div class="logo-box-text">{{ projectName }}</div>
          <el-form :model="ruleForm" :status-icon="false" :rules="rules" ref="ruleForm" class="demo-ruleForm"
            hide-required-asterisk :validate-on-rule-change="false">
            <!-- 账号 -->
            <el-form-item prop="pass" :label="$t('login.account')" ref="pass">
              <el-input type="text" v-model.trim="ruleForm.pass" autocomplete="off" :placeholder="$t('login.account')"
                @focus="clearMsgErr('pass')"></el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="checkPass" :label="$t('login.password')" ref="checkPass">
              <el-input show-password v-model.trim="ruleForm.checkPass" autocomplete="off"
                :placeholder="$t('login.password')" @focus="clearMsgErr('checkPass')"></el-input>
            </el-form-item>
            <!-- 验证码 -->
            <el-form-item prop="age" class="test" :label="$t('login.verificationCode')" ref="age">
              <el-input v-model.trim="ruleForm.age" :placeholder="$t('login.verificationCode')"
                @focus="clearMsgErr('age')"></el-input>
              <img :src="imgSrc" alt="" @click="refreshimg" class="verification" />
            </el-form-item>
          </el-form>
          <div class="err-text">{{ msgErr }}</div>
          <div class="agree-consent">
            <el-checkbox v-model="isCheckBoxAgree" class="agreeCheckBox"></el-checkbox>
            <div class="my-agree">
              <template v-if="this.$i18n.locale == 'zh'">
                我已阅读，同意并接受<el-link @click="handleUrl" class="agreeLink1">《用户协议》和《隐私条</el-link>
                <el-link @click="handleUrl" class="agreeLink2">款》</el-link>
              </template>
              <!-- 我已阅读，同意并接受<span>《用户协议》</span>和<span>《隐私条款》</span> -->

              <template v-else>
                By accessing the system, you agree to<el-link @click="handleUrl" class="agreeLink3">Terms &</el-link>
                <el-link @click="handleUrl" class="agreeLink2">Conditions</el-link>
              </template>
            </div>
          </div>
          <!-- 登录 -->
          <el-button class="login-btn" style="text-align: center;" type="primary" :loading="loading"
            @click.stop="submitForm('ruleForm')" :disabled="!isCheckBoxAgree">
            {{ $t('login.login') }}
            <!--<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>-->
          </el-button>
          <div class="login-text-box">
            <!-- <div class="box-forget" @click="ForgetPwd">
              {{ $t('login.forgetPwd') }}
            </div> -->
            <div></div>
            <div>
              <el-dropdown @command="changeLang">
                <span class="box-forget">
                  {{ langString
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                  <el-dropdown-item command="en">English</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
        <!--重置密码-->
        <template v-if="reSetPwd">
          <div class="logo-box-text">Reset Password</div>
          <el-form :model="ReSetruleForm" :status-icon="false" :rules="ReSetruleFormrules" ref="ReSetruleForm"
            class="demo-ruleForm" hide-required-asterisk :validate-on-rule-change="false">
            <!-- 密码 -->
            <el-form-item prop="pass" :label="$t('login.password')" ref="pass">
              <el-input show-password v-model.trim="ReSetruleForm.pass" autocomplete="off"
                :placeholder="$t('login.password')" @focus="clearMsgErr('pass')"></el-input>
            </el-form-item>
            <el-form-item prop="pass" label="覆盖第二个框对应的密码反显问题" v-show="false">
              <el-input show-password autocomplete="off" placeholder="第二密码"></el-input>
            </el-form-item>
            <!-- 确认密码 -->
            <el-form-item prop="checkPass" :label="$t('login.confirmNewPassword')" ref="checkPass">
              <el-input type="password" show-password v-model.trim="ReSetruleForm.checkPass" autocomplete="off"
                :placeholder="$t('login.confirmNewPassword')" @focus="clearMsgErr('checkPass')"></el-input>
            </el-form-item>
          </el-form>
          <div class="err-text" :title="msgErr">{{ msgErr }}</div>
          <!-- 重置 -->
          <el-button class="login-btn" style="text-align: center;" type="primary"
            @click.stop="ResubmitForm('ReSetruleForm')">
            {{ $t('login.resetPassword') }}
          </el-button>
          <!-- 返回 -->
          <el-button class="login-btn" style="text-align: center;margin-left:0px;" type="primary"
            @click.stop="cancleRe('ReSetruleForm')">
            {{ $t('login.return') }}
          </el-button>
        </template>
      </template>

      <!--忘记密码-->
      <template v-if="forgetShow">
        <div class="logo-box-text">Forget Password</div>
        <el-form :model="ForgetruleForm" :status-icon="false" :rules="ForgetruleFormrules" ref="ForgetruleForm"
          class="demo-ruleForm" hide-required-asterisk :validate-on-rule-change="false">
          <!-- 新密码 -->
          <el-form-item prop="pass" :label="$t('login.newPassword')" ref="pass">
            <el-input show-password v-model.trim="ForgetruleForm.pass" autocomplete="off"
              :placeholder="$t('login.newPassword')" @focus="clearMsgErr('pass')"></el-input>
          </el-form-item>
          <el-form-item prop="pass" label="覆盖第二个框对应的密码反显问题" v-show="false">
            <el-input show-password autocomplete="off" placeholder="第二密码"></el-input>
          </el-form-item>
          <!-- 确认密码 -->
          <el-form-item prop="checkPass" :label="$t('login.confirmNewPassword')" ref="checkPass">
            <el-input show-password v-model.trim="ForgetruleForm.checkPass" autocomplete="off"
              :placeholder="$t('login.confirmNewPassword')" @focus="clearMsgErr('checkPass')"></el-input>
          </el-form-item>
          <!-- 邮箱 -->
          <el-form-item prop="email" :label="$t('login.email')" ref="email">
            <el-input v-model.trim="ForgetruleForm.email" autocomplete="off" :placeholder="$t('login.email')"
              @focus="clearMsgErr('email')"></el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item prop="phoneCode" :label="$t('login.verificationCode')" ref="phoneCode">
            <el-input v-model.trim="ForgetruleForm.phoneCode" autocomplete="off"
              :placeholder="$t('login.verificationCode')" @focus="clearMsgErr('phoneCode')"></el-input>
            <!-- 发送验证码 -->
            <div @click="getVerificationCode" element-loading-spinner="el-icon-loading" v-loading="sentding"
              class="verification verification-sent">
              {{ count > 0 ? count + 's' : $t('login.sendCode') }}
            </div>
          </el-form-item>
        </el-form>
        <div class="err-text" :title="msgErr">{{ msgErr }}</div>
        <!-- 重置 -->
        <el-button type="primary" class="login-btn" style="text-align: center;margin-bottom: 10px;"
          @click.stop="ResubmitForm('ForgetruleForm')">
          {{ $t('login.resetPassword') }}
        </el-button>
        <!-- 返回 -->
        <el-button type="primary" class="login-btn" style="text-align: center;margin-bottom: 0px; margin-left:0px"
          @click.stop="cancleRe('ForgetruleForm')">
          {{ $t('login.return') }}
        </el-button>
      </template>
    </div>
    <!-- <div class="login-bottom-text">
      <span>© 2020 Linklogis International Company Limited | </span><el-link @click="handleUrl">Terms &
        Conditions</el-link>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'loginSSO',
  components: {
  },
  data() {
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        age: '',
      },
      ReSetruleForm: {
        pass: '',
        checkPass: '',
      },
      ForgetruleForm: {
        pass: '',
        checkPass: '',
        email: '',
        phoneCode: '',
      },
      imgSrc: '',
      show: false,
      reSetPwd: false, //重置密码
      msgErr: '',
      tokenUuid: '',
      projectName: null,
      username: '',
      signContractId: '',
      pdfUrl: '',
      sysChannel: 'kj_my_pc',
      forgetShow: false,
      timer: null,
      isDisabled: false,
      count: 0,
      sentding: false,
      currentComp: {}, //选择的企业
      loginErrCode: '',
      langString: this.$i18n.locale == 'zh' ? '简体中文' : 'English',
      loading: false,
      customer: 'DEFAULT',
      logoMap: {
        DEFAULT: require('@/static/projImg/logo.svg'),
        volofin: require('@/static/projImg/logo-volofin.svg'),
      },
      isCheckBoxAgree: true, //同意协议才能登录
    }
  },
  computed: {
    rules() {
      const rules = {
        pass: [
          {
            required: true,
            message: this.$t('login.PleaseEnterUsername'),
            trigger: 'submit',
          },
        ],
        checkPass: [
          {
            required: true,
            message: this.$t('login.pleaseEnterPassword'),
            trigger: 'submit',
          },
        ],
        age: [
          {
            required: true,
            message: this.$t('login.pleaseEnterVerification'),
            trigger: 'submit',
          },
        ],
      }
      this.$nextTick(() => {
        this.$refs['ruleForm'].fields.forEach(item => {
          if (item.validateState === 'error') {
            this.$refs['ruleForm'].validateField(item.prop)
          }
        })
      })
      return rules
    },
    ForgetruleFormrules() {
      var validatePass3 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('login.pleaseReenterPassword')))
        } else if (value !== this.ForgetruleForm.pass) {
          callback(new Error(this.$t('login.passwordIncorrectAgain')))
        } else {
          callback()
        }
      }
      const ForgetruleFormrules = {
        pass: [
          {
            required: true,
            message: this.$t('login.pleaseEnterNewPassword'),
            trigger: 'submit',
          },
        ],
        checkPass: [{ validator: validatePass3, trigger: 'submit' }],
        email: [
          {
            required: true,
            message: this.$t('login.pleaseEnterEmail'),
            trigger: 'submit',
          },
        ],
        phoneCode: [
          {
            required: true,
            message: this.$t('login.pleaseEnterVerification'),
            trigger: 'submit',
          },
        ],
      }
      return ForgetruleFormrules
    },
    ReSetruleFormrules() {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          // 请再次输入密码
          callback(new Error(this.$t('login.pleaseReenterPassword')))
        } else if (value !== this.ReSetruleForm.pass) {
          // 两次输入密码不一致
          callback(new Error(this.$t('login.passwordIncorrectAgain')))
        } else {
          callback()
        }
      }
      const ReSetruleFormrules = {
        pass: [
          {
            required: true,
            message: this.$t('login.pleaseEnterPassword'),
            trigger: 'submit',
          },
        ],
        checkPass: [{ validator: validatePass2, trigger: 'submit' }],
      }
      return ReSetruleFormrules
    },
    logo() {
      const res = this.logoMap[this.customer]
      return res
    },
  },
  created() {
    this.changeWhiteLabel()
  },
  async mounted() {
    sessionStorage.clear()
    const query = this.$route.query
    if (query.refer) {
      this.customer = query.refer.toLowerCase()
    }
    //判断参数国际化参数
    let urlLang = this.$route.query.lang || this.$i18n.locale
    if (urlLang == 'EN' || urlLang == 'En' || urlLang == 'en') {
      //英文环境
      this.changeLang('en')
    } else {
      //默认中文环境
      this.changeLang('zh')
    }
    await this.checkLogin()
    await this.refreshimg()
    this.ruleForm.pass = this.$route.query.userName || ''
    //绑定事件，其他界面可能要写在methods里
    window.addEventListener('keydown', this.keyDown)
    //清除上次缓存的刷新页面
    sessionStorage.removeItem('flashPath')
    sessionStorage.removeItem('flashPathName')
  },

  // mounted:function(){
  //     sessionStorage.clear()
  //     this.refreshimg()
  //     this.ruleForm.pass = this.$route.query.userName || ''
  // },
  methods: {
    handleUrl() {
      const res = `${window.location.protocol}//${window.location.host}/#/terms`
      window.open(res, '_blank')
    },
    //最终选择企业进入
    async chooseFinal(table) {
      //判断该企业有无菜单，无菜单没有权限
      let curLoginName = sessionStorage.getItem('curLoginName')
      const powerFlag = await this.$API.enterprise.getMenuByEnterpriseId({ "sysChannel": "kj_my", enterpriseId: table.id, loginName: curLoginName })
      if (!powerFlag) return
      sessionStorage.setItem('enterpriseId', table.id)
      let _this = this
      let status = JSON.parse(table.status).dictParam //ENDURANCE  审核通过
      try {
        const data = await _this.$http.get(
          `/kj-web/sys/setOrgId?orgId=${table.id}`
        )
        const queryObj = await _this.$http.get(
          // `/kj-kyc/product/getByEnterpriseId?enterpriseId=${table.id}`
          `/kj-kyc/product/listByEnterpriseId?enterpriseId=${table.id}`
        )
        let productTypeArr = queryObj.data.data || []
        //国际结算特殊处理
        let calcCompany = productTypeArr.filter(item => {
          let productType = this.filterJSONString(item.productType)
          return productType == 'PURCHASE_PAYMENT'
        })
        if (calcCompany && calcCompany.length > 0) {
          //存在国际结算产品
          if (calcCompany.length == productTypeArr.length) {
            //有且只有一个国际计算产品
            sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', true)
          } else {
            sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', false)
          }
        } else {
          sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', false)
        }
        let productTypeObj = productTypeArr[0] || {}
        var productType = productTypeObj.productType ? JSON.parse(productTypeObj.productType).dictParam : ''
        sessionStorage.setItem('enterpriseProductType', productType)

        const abtRole = productTypeArr.find(v => JSON.parse(v.productType).dictParam == 'ABT')
        abtRole && sessionStorage.setItem('abtRole', JSON.parse(abtRole.enterpriseRole).dictParam)

        var code = data.data.code || ''

        if (code == '200') {
          // _this.$store.state.currentCompany = table
          if (status == 'ENDURANCE') {
            //审核通过
            const query = this.$route.query
            // _this.$router.push({ path: '/bigHome', query })
            _this.$router.push({ path: '/home', query })
          } else if (status == 'INIT' && productType == 'E_FINANCE') {
            //待完善 产品类型为电商融资
            _this.$router.push({
              path: '/childEkycPortal/efinKyc',
              query: { id: table.id, status: status },
            })
          } else if (status == 'BACK' && productType == 'E_FINANCE') {
            //审核退回 产品类型为电商融资
            _this.$router.push({
              path: '/childEkycPortal/efinKyc',
              query: { id: table.id, status: status },
            })
          } else if (status == 'SUBMIT' && productType == 'E_FINANCE') {
            //建档审核中 产品类型为电商融资
            _this.$router.push({
              path: '/childEkycPortal/efinKyc',
              query: { id: table.id, status: status },
            })
          } else if (status == 'INIT' && productType == 'IDD') {
            //待完善 产品类型为IDD
            _this.$router.push({
              path: '/kycFileCN',
              query: { id: table.id, status: status },
            })
          } else if (status == 'BACK' && productType == 'IDD') {
            //审核退回 产品类型为IDD
            _this.$router.push({
              path: '/kycFileCN',
              query: { id: table.id, status: status },
            })
          } else if (status == 'SUBMIT' && productType == 'IDD') {
            //建档审核中 产品类型为IDD
            _this.$router.push({
              path: '/kycFileCN',
              query: { id: table.id, status: status },
            })
          } else {
            // '建档状态异常，请联系管理员'
            _this.$alert(
              _this.$t('login.onboardingStatusAbnormal'),
              _this.$t('login.tips'),
              {
                confirmButtonText: _this.$t('login.confirm'),
              }
            )
          }
        } else {
          // $.fn.mAlert('body',{tips:data.body.msg});
          _this.$alert(data.body.message, _this.$t('login.tips'), {
            confirmButtonText: _this.$t('login.confirm'),
          })
        }
      } catch (response) {
        // $.fn.mAlert('body',{tips:response});
        _this.$alert(response, _this.$t('login.tips'), {
          confirmButtonText: _this.$t('login.confirm'),
        })
      }
    },
    //选择一个企业
    async chooseOne(table) {
      await this.chooseFinal(table)
    },
    //获取企业
    async getCompList() {
      let _this = this
      //   this.loading = true;
      try {
        // const data = await _this.$http.post('/kj-web/sys/getEnterpriseVO')
        const data = await _this.$http.post('/kj-sys/kjEnterprise/getEnterpriseByType', { productType: ['IDD', 'DOC_EXCHANGE', 'PURCHASE_PAYMENT', 'E_FINANCE', 'MARKET', 'LDA', 'ABT'] })
        var code = data.data.code
        if (code == '200') {
          // _this.$router.push('/login');
          let tl = data.data.data || []
          // 内管端有且只有一个企业
          // tl.map(item=>{
          //     this.userName = item.entCnName || ''
          // })
          const enterpriseRole = tl[0]?.enterpriseRole ? JSON.parse(tl[0]?.enterpriseRole)?.dictParam : null
          sessionStorage.setItem('enterpriseRole', enterpriseRole)

          if (tl.length > 1) {
            const query = this.$route.query
            _this.$router.push({ path: '/companyChoose', query })
          } else {
            if (tl.length == 1) {
              //只有一个企业
              //再判断是否需要签署
              await _this.chooseOne(tl[0])
            } else {
              // 您暂无企业，请联系运营人员协助处理，谢谢
              _this.$alert(
                _this.$t('login.noCompanyFoundContact'),
                _this.$t('login.tips'),
                {
                  confirmButtonText: _this.$t('login.confirm'),
                }
              )
            }
          }
        } else {
          // $.fn.mAlert('body',{tips:data.body.msg});
          _this.$alert(data.body.message, _this.$t('login.tips'), {
            confirmButtonText: _this.$t('login.confirm'),
          })
        }
      } catch (response) {
        // $.fn.mAlert('body',{tips:response});
        _this.$alert(response, _this.$t('login.tips'), {
          confirmButtonText: _this.$t('login.confirm'),
        })
      } finally {
        // this.loading = false;
      }
    },
    //修改语言
    changeLang(lang) {
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang
      if (lang == 'zh') {
        this.langString = '简体中文'
      } else {
        this.langString = 'English'
      }
      //通知子应用更新
      this.$setGlobalState({ langlocale: lang })
    },

    //绑定监听事件
    keyDown(e) {
      //如果是按回车则执行登录方法
      if (e.keyCode == 13) {
        if (!this.reSetPwd && !this.forgetShow) {
          this.submitForm('ruleForm')
        }
      }
    },
    getVerificationCode() {
      if (this.count > 0) {
        return
      } else {
        let _this = this
        let email = this.ForgetruleForm.email
        if (!email) {
          // 请输入邮箱
          this.msgErr = this.$t('login.pleaseEnterEmail')
          return
        }
        if (_this.sentding) return
        _this.sentding = true
        _this.$http
          .get(`/kj-sys/setting/sendForgetPwdCode?email=${email}`)
          .then(
            function (data) {
              _this.sentding = false
              // 验证码已发送，请查收
              _this.$message({
                message: _this.$t('login.verificationSentInbox'),
                type: 'success',
              })
              _this.countDown()
            },
            function (response) {
              _this.sentding = false
            }
          )
      }
    },
    countDown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.isDisabled = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.isDisabled = false
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    //================================SSO=============================
    async checkLogin() {
      // setTimeout(async()=>{
      //     await this.$sso.checkLogin()
      // },0)
      try {
        setTimeout(async () => {
          var res = await this.$sso.checkLogin()
          if (res) {
            const query = this.$route.query
            // this.$router.push({ path: '/companyChoose', query })
            this.getCompList()
          }
        }, 0)
      } catch (e) {
        //e.message 是一个JSON，格式：'{"code":"10901","message":"账号或密码错误"}'
        var obj = JSON.parse(e.message)
      }
    },
    async doLogin() {
      this.loading = true
      try {
        let data = await this.$sso.doLogin({
          ifRemember: false, //是否记住
          loginName: this.ruleForm.pass, //用户账号*
          password: this.ruleForm.checkPass, //用户密码*
          imgCode: this.ruleForm.age, //图形验证码*
          encrypt: true, //是否加密，暂时可以不加密，过段时间宽哥会强制加密
          sysChannel: this.sysChannel, //所属系统*
        })
        if (data.code == '200') {
          if (data.switchCode && data.switchCode == '30900') {
            //新用户请初始化密码 ;
            this.$message({
              message: this.$t('login.pleaseResetPassword'),
              type: 'success',
            })
            this.reSetPwd = true
          } else {
            // this.$router.push('/companyChoose');
            sessionStorage.setItem('curLoginName', this.ruleForm.pass)
            await this.getCompList()
          }

        }
      } catch (error) {
        var err = JSON.parse(error.message)
        this.refreshimg()
        if (err.code == '30903') {
          //密码已过期，请修改密码
          this.$message.error(err.message)
          this.reSetPwd = true
          this.loginErrCode = err.code
        }
        this.msgErr = err.message
        // debugger
        // if (err.code === '10901') {
        //     this.msgErr=err.message
        // } else if (err.code === '10924') {
        //     this.$message.error('验证码不正确')
        // } else if (err.code === '10923') {
        //     this.refreshimg()
        //     this.$message.error('验证码失效')
        // }
      } finally {
        this.loading = false
      }
    },
    //======================================================
    closePdf() {
      this.agreementShow = false //协议显示
      this.pdfUrl = ''
      // 当关闭签署的时候，需要清除缓存和退出登录，点击登录的时候其实已经有cookie了所以需要退出登录
      sessionStorage.clear()
      this.$sso.logout()
    },
    //确认签署
    sureSign() {
      this.$http.post(`/kj-contract/contractInfo/signContract?id=${this.signContractId}`).then((data)=> {
        // this.$router.push('/home')
        this.chooseFinal(this.currentComp)
      })
    },
    cancleRe(formName) {
      this.$refs[formName].resetFields()
      if (formName.includes('ForgetruleForm')) {
        this.forgetShow = false
      } else {
        this.reSetPwd = false
        this.loginErrCode = ''
      }
      this.msgErr = ''
    },
    clearMsgErr(formName) {
      this.msgErr = ''
      if (typeof formName == 'string') {
        this.$refs[formName].clearValidate()
      }
    },
    async refreshimg() {
      this.imgSrc = await this.$sso.captchaImage({
        sysChannel: this.sysChannel,
      })
    },
    async forgetPwdSSO() {
      //验证验证码
      // ForgetruleForm:{
      //     pass: '',
      //         checkPass: '',
      //         email:'',
      //         phoneCode:'',
      // },
      let code = this.ForgetruleForm.phoneCode
      let email = this.ForgetruleForm.email
      if (this.sentding) return
      this.sentding = true
      try {
        let params = {
          loginName: email,
          password: this.ForgetruleForm.pass,
          phoneCode: code,
          sysChannel: this.sysChannel,
        }
        let res = await this.$sso.forgetPasswd(params)
        this.sentding = false

        if (res.code == '200') {
          // 密码重置成功，请重新登录
          this.$message({
            message: this.$t('login.passwordResetLogin'),
            type: 'success',
          })
          // this.$router.replace('/login')
          this.cancleRe('ForgetruleForm')
          this.refreshimg()
        }
      } catch (error) {
        this.sentding = false
        let err = JSON.parse(error.message).message
        // this.$message.error(err)
        // 暂无响应，请联系管理员
        this.msgErr = err || _this.$t('login.serverNotRsponding')
      }
      // this.$http.get(
      //     `/kj-sys/setting/verifyForgetPwdCode?code=${code}&email=${email}`,{handingResponseErrMsg:true}
      // ).then(async data=>{
      //     if(data.data.code == 200){

      //     }
      // },err=>{
      //     this.sentding =false
      //     this.msgErr = err
      // })
    },
    ResubmitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // alert('submit!');
          // this.initPwd()
          if (formName.includes('ForgetruleForm')) {
            this.forgetPwdSSO()
          } else {
            if (this.loginErrCode == '30903') {
              this.resetPasswdSSO()
            } else {
              this.initPwdSSO()
            }
          }

          // this.cancleRe('ReSetruleForm')
        } else {
          return false
        }
      })
    },
    initComp() {
      let _this = this
      _this.$http.post(`kj-kyc/enterpriseContact/updateLoginFlag`, {}).then(
        function (data) { },
        err => { }
      )
    },
    //密码过期重新修改
    async resetPasswdSSO() {
      let { sysChannel } = this
      let { pass, checkPass } = this.ReSetruleForm
      let loginName = this.ruleForm.pass
      let orcPassword = this.ruleForm.checkPass
      // let invitationCode = this.ruleForm.checkPass
      let password = pass
      let confirmPassword = checkPass
      try {
        let data = await this.$sso.resetPasswd({
          loginName,
          // invitationCode,
          password,
          confirmPassword,
          sysChannel,
          orcPassword,
        })

        if (data.code == '200') {
          // '密码重置成功，请重新登录'
          this.$message({
            message: this.$t('login.passwordResetLogin'),
            type: 'success',
          })
          // this.$router.replace('/login')
          this.cancleRe('ReSetruleForm')
          this.ruleForm.checkPass = ''
          this.ruleForm.age = ''
          this.refreshimg()
          //首次登录，修改密码后调用一下这个接口
          // this.initComp()
        }
      } catch (error) {
        let err = JSON.parse(error.message).message
        // this.$message.error(err)
        // 暂无响应，请联系管理员
        this.msgErr = err || _this.$t('login.serverNotRsponding')
      }
    },
    //首次登录重置密码
    async initPwdSSO() {
      let { sysChannel } = this
      let { pass, checkPass } = this.ReSetruleForm
      let loginName = this.ruleForm.pass
      let invitationCode = this.ruleForm.checkPass
      let password = pass
      let confirmPassword = checkPass
      try {
        let data = await this.$sso.initPasswd({
          loginName,
          invitationCode,
          password,
          confirmPassword,
          sysChannel,
        })
        if (data.code == '200') {
          // '密码重置成功，请重新登录'
          this.$message({
            message: this.$t('login.passwordResetLogin'),
            type: 'success',
          })
          // this.$router.replace('/login')
          this.cancleRe('ReSetruleForm')
          this.ruleForm.checkPass = ''
          this.ruleForm.age = ''
          this.refreshimg()
          //首次登录，修改密码后调用一下这个接口
          // this.initComp()
        }
      } catch (error) {
        let err = JSON.parse(error.message).message
        // this.$message.error(err)
        // 暂无响应，请联系管理员
        this.msgErr = err || _this.$t('login.serverNotRsponding')
      }
    },
    initPwd() {
      let _this = this
      let password = this.ReSetruleForm.pass
      let confirmPassword = this.ReSetruleForm.checkPass
      _this.$http
        .post(
          `/kj-sso/account/initPwd?confirmPassword=${confirmPassword}&password=${password}&tokenUuid=${_this.tokenUuid}&userName=${_this.username}`,
          {},
          { handingResponseErrMsg: true }
        )
        .then(
          function (data) {
            var code = data.data.code
            if (code == '200') {
              _this.cancleRe('ReSetruleForm')
              _this.ruleForm.checkPass = ''
              _this.ruleForm.age = ''
              _this.refreshimg()
            } else {
              _this.refreshimg()
              // 暂无响应，请联系管理员
              _this.msgErr =
                data.body.msg ||
                data.body.message ||
                _this.$t('login.serverNotRsponding')
            }
          },
          function (response) {
            _this.refreshimg()
            // 暂无响应，请联系管理员
            _this.msgErr = response || _this.$t('login.serverNotRsponding')
          }
        )
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        try {
          //   this.loading = true;
          if (valid) {
            // alert('submit!');
            // this.login() //非加密
            // this.logon() //加密登录
            await this.doLogin() //sso登录
          } else {
            return false
          }
        } catch (e) {
        } finally {
          //   this.loading = false;
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //获取公钥
    async getPubKey() {
      let _this = this
      let result = ''
      result = await _this.$http.post(`/kj-sso/account/getPublicKey`).then(
        function (data) {
          var code = data.data.code
          if (code == '200') {
            return data.body.data
          } else {
            // _this.$llsAlert({text: data.body.msg || data.body.message});
            _this.msgErr = data.body.msg || data.body.message
            return ''
          }
        },
        function (response) {
          // _this.$llsAlert({text: response});
          _this.msgErr = response
          return ''
        }
      )
      return result
    },
    //加密登录
    async logon(data) {
      let getPublicKey = ''
      getPublicKey = await this.getPubKey()
      if (!getPublicKey) {
        return
      }
      var _this = this
      var userName = this.ruleForm.pass || ''
      var password =
        this.encryptedData(this.ruleForm.checkPass, getPublicKey) || ''
      var picCheckCode = this.ruleForm.age || ''
      _this.username = userName
      // this.$router.push('/home');
      // _this.state = "";
      _this.$http
        .post(`/kj-sso/account/logon`, { userName, password, picCheckCode })
        .then(
          function (data) {
            var code = data.data.code
            if (code == '200') {
              // sessionStorage.setItem("objUser", JSON.stringify(data.data.data))
              // if(window.location.href.includes('localhost')){
              //     document.cookie = "llskj="+data.data.data.sessionId;
              // }
              // _this.$router.push('/home');
              // // _this.getUserInfo()
              let result = data.data.data || {}
              if (!result.tokenUuid) {
                sessionStorage.setItem(
                  'objUser',
                  JSON.stringify(data.data.data)
                )
                if (window.location.href.includes('localhost')) {
                  document.cookie = 'llskj=' + data.data.data.sessionId
                }
                // _this.$router.push('/companyChoose');
                _this.$router.push('/kycFileCN')
              } else {
                //存在tokenUuid需要重置密码
                _this.tokenUuid = result.tokenUuid
                _this.reSetPwd = true
              }
            } else {
              _this.refreshimg()
              // 暂无响应，请联系管理员
              _this.msgErr =
                data.body.msg ||
                data.body.message ||
                _this.$t('login.serverNotRsponding')
              // _this.state = "loginFail";
              // _this.$llsAlert({text: data.body.msg || data.body.message});
            }
          },
          function (response) {
            _this.refreshimg()
            // 暂无响应，请联系管理员
            _this.msgErr = response || _this.$t('login.serverNotRsponding')
            // _this.state = "loginFail";
            // _this.$llsAlert({text: response});
          }
        )
    },
    //登录
    login: function (data) {
      var _this = this
      var username = this.ruleForm.pass || ''
      var password = this.ruleForm.checkPass || ''
      var vericode = this.ruleForm.age || ''
      this.username = this.ruleForm.pass
      // this.$router.push('/home');
      // _this.state = "";
      _this.$http
        .post(
          '/kj-sso/account/login?userName=' +
          username +
          '&password=' +
          password +
          '&picCheckCode=' +
          vericode,
          {},
          { handingResponseErrMsg: true }
        )
        .then(
          async function (data) {
            var code = data.data.code
            if (code == '200') {
              // sessionStorage.setItem("objUser", JSON.stringify(data.data.data))
              // if(window.location.href.includes('localhost')){
              //     document.cookie = "llskj="+data.data.data.sessionId;
              // }
              // _this.$router.push('/home');
              // // _this.getUserInfo()
              let result = data.data.data || {}
              if (!result.tokenUuid) {
                sessionStorage.setItem(
                  'objUser',
                  JSON.stringify(data.data.data)
                )
                if (window.location.href.includes('localhost')) {
                  document.cookie = 'llskj=' + data.data.data.sessionId
                }
                _this.$router.push('/companyChoose')

                // _this.getUserInfo()
              } else {
                //存在tokenUuid需要重置密码
                _this.tokenUuid = result.tokenUuid
                _this.reSetPwd = true
              }
            } else {
              _this.refreshimg()
              // 暂无响应，请联系管理员
              _this.msgErr =
                data.body.msg ||
                data.body.message ||
                _this.$t('login.serverNotRsponding')
              // _this.state = "loginFail";
              // _this.$llsAlert({text: data.body.msg || data.body.message});
            }
          },
          function (response) {
            _this.refreshimg()
            // 暂无响应，请联系管理员
            _this.msgErr = response || _this.$t('login.serverNotRsponding')
            // _this.state = "loginFail";
            // _this.$llsAlert({text: response});
          }
        )
    },
    //忘记密码
    ForgetPwd() {
      this.$refs['ruleForm'].resetFields()
      this.forgetShow = true
    },
    async changeWhiteLabel () {
      const { data } = await this.$API.system.getNoAuthAllWhiteLabel()
      const whiteLabelList = data.data
      this.$store.commit('saveWhiteLabel', whiteLabelList)

      this.projectName = whiteLabelList?.loginTitle || 'Project Guardian'
      document.title = whiteLabelList?.documentTitle || 'Project Guardian'
      // 登录页图标
      const loginFavicon = whiteLabelList?.loginFavicon ?(Buffer.from(whiteLabelList.loginFavicon, 'base64')).toString('binary') : ''
      document.querySelector('.loginLogo').innerHTML = loginFavicon

      // 改变标签页的图标
      // 先移除现有的
      const documentFavicon = whiteLabelList?.documentFavicon ? Buffer.from(whiteLabelList.documentFavicon, 'base64') : '' 
      // 拼装图标用于标签页的图标
      if (documentFavicon) {
        document.querySelector('link[rel="icon"]').remove()
        const blob = new Blob([documentFavicon]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/svg+xml';
        document.head.appendChild(link);
        link.href = url;
      }
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
}
</script>
