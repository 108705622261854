import Vue from 'vue'
//注册一个全局的表格loading组件
Vue.directive('mLoading', function (el, binding) {
    var loadingDom = $("<div class='svg-load-div'><div class='div-svg'><svg viewBox='25 25 50 50' class='circular'><circle cx='50' cy='50' r='20' fill='none' class='path'></circle></svg></div></div>")
    if(binding.value){//添加loading
        $(el).css('position','relative')
        $(el).append(loadingDom)
    }else{//移除loading
        $(el).css('position','static');
        $('.svg-load-div').remove()
    }
})
//提交按钮的loading组件
Vue.directive('btnLoading',function(el,binding){
    var loadingDom = $("<div class='svg-load-div'><div class='div-svg'><svg viewBox='25 25 50 50' class='circular circular-btn'><circle cx='50' cy='50' r='20' fill='none' class='path path-btn'></circle></svg></div></div>")
    if(binding.value){//添加loading
        $(el).css('position','relative')
        $(el).append(loadingDom)
    }else{//移除loading
        $('.svg-load-div').remove()
    }
})
//tree loading组件
Vue.directive('treeLoading',function(el,binding){
    var loadingDom = $("<div class='svg-load-div'><div class='div-svg'><svg viewBox='25 25 50 50' class='circular circular-btn'><circle cx='50' cy='50' r='20' fill='none' class='path path-btn'></circle></svg></div></div>")
    if(binding.value){//添加loading
        $(el).append(loadingDom)
    }else{//移除loading
        $('.svg-load-div').remove()
    }
})
//整页的loading
Vue.directive('bLoading',function(el,binding){
    var loadingDom = $("<div class='fixed-load-div'><div class='div-fixed'><svg viewBox='25 25 50 50' class='circular-fixed'><circle cx='50' cy='50' r='20' fill='none' class='path-fixed'></circle></svg><div>loading</div></div></div>")
    if(binding.value){//添加loading
        $(el).css('position','relative')
        $(el).append(loadingDom)
    }else{//移除loading
        $('.fixed-load-div').remove()
    }
})
// 除去自身元素外的点击事件
Vue.directive("clickoutside", {
    bind(el, binding, vnode) {
        function documentHandler(e) {
          // 这里判断点击的元素是否是本身，是本身，则返回
          if (el.contains(e.target)) {
            return false;
          }
          // 判断指令中是否绑定了函数
          if (binding.expression) {
            // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
            binding.value(e);
          }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
      },
      update() {},
      unbind(el, binding) { // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
      }
});