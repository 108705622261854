<style lang="scss" scoped>
.enterprise{
    padding: 0 30px;
    height: 525px;
    overflow: auto;        
}
.content-right-title{
    
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    text-align: left;
    color: #4e78b1;
    margin: 50px 0;
}
.content-right-basics{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-basics span{
    
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #99a3b1;
}
.content-right-line{
    width: 100%;
    height: 2px;
    background-color: #f3f8ff;
    margin-bottom: 20px;
}
.upload-icon{
    width: 23px;
    height:27px;
    background:url("~@/static/img/uploadIcon.svg") center center no-repeat;
    margin-right: 5px;
}
.kyc-upload{
    width: 340px;
    height: 180px;
    
    border-radius: 8px;
    overflow: auto;

}
.kyc-upload-active{
    background-color: #f9fcff;
}
.balance-dialog-icon{
    width: 45px;
    height: 45px;
    background:url("~@/static/img/return.svg") center center no-repeat;
    margin: 0 auto;
}
.balance-dialog-words{
    
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #f76c5e;
    margin-top: 10px;
}
.balance-dialog-tips{
    
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    color: #4a4a4a;
    margin-top: 30px;
}
.submit-success-again{
    width: 68px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;
    margin:20px auto;
    background-image: linear-gradient(to top, #5a9cf8, #90befd);
}
.submit-success-again span{
    
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #ffffff;
}
</style>

<template>
    <div class="enterprise" v-bLoading="sonLoading">
        <!-- 企业信息 -->
        <div class="content-right-title">{{$t('enterprise.tellYourCompany')}}</div>
        <el-form :model="ruleForm" :inline="true" label-position="top"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <!-- 基本信息 -->
                <div class="content-right-basics">{{$t('enterprise.companyInfo')}}</div>
                <el-form-item :label="'*'+ $t('enterprise.companyNameEnglish')" prop="companyName">
                    <el-input v-model="enterpriseList.companyName" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.companyNameChinese')" prop="companyNameCn">
                    <el-input v-model="enterpriseList.companyNameCn" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.countryIncorporation')" prop="registeredAreaCode">
                    <el-select v-model="enterpriseList.registeredAreaCode" @change="changeCountry" :disabled="location == 'TERRITORY'" filterable :inline="true" placeholder="请选择">
                        <el-option v-for="(item, index) in countryList" :key="index" :label="item.countryName" :value="item.countryCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.companyDocsType')" prop="companyIdentType">
                    <el-select v-model="enterpriseList.companyIdentType" :disabled="location == 'TERRITORY'" :inline="true" placeholder="请选择">
                        <el-option  v-for="(item, index) in enterpriseObj" :key="index" :label="item.dispalyName" :value="item.dictParam"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.registeredCity')" prop="registeredCity" v-show="isCityShow">
                    <el-cascader
                    size="large"
                    :options="options"
                    ref="cascaderAddr"
                    v-model="selectedOptions"
                    @change="handleChange">
                    </el-cascader>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.registeredCityElse')" prop="registeredCity" v-show="!isCityShow">
                    <el-input v-model="enterpriseList.registeredCity" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.registeredAddress')" prop="registeredDetail" v-if="isOcrShow">
                    <el-input v-model="enterpriseList.registeredDetail" width="300px" @input="oninput"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('enterprise.companyDocsNo')" prop="companyIdentCode" v-if="isOcrShow">
                    <el-input v-model="enterpriseList.companyIdentCode" width="300px" @input="oninput"></el-input>
                </el-form-item>
                <div class="content-right-line"></div>
                <!-- 上传文件 -->
                <div class="content-right-basics">{{$t('common.documents')}} <span>{{$t('common.pleaseUploadClear')}}</span></div>
                <!-- 大陆影像件 -->
                <!-- 营业执照正面照 -->
                <el-form-item prop="value6" :label="'*'+ $t('enterprise.businessLicence')" v-if="isFileShow == 1">
                    <kycUpload v-if="enterpriseList.mediaId" @toOcr="ocrEvent" :mediaId="enterpriseList.mediaId" modelCode="MKJ01ENT" catgId="CENT005"></kycUpload>                    
                </el-form-item>
                <!-- 香港影像件 -->
                <!-- 商业登记证正面照 -->
                <el-form-item prop="value6" :label="'*'+ $t('enterprise.businessRegistration')" v-if="isFileShow == 2">
                    <kycUpload v-if="enterpriseList.mediaId" :mediaId="enterpriseList.mediaId" modelCode="MKJ01ENT" catgId="CENT001"></kycUpload>
                </el-form-item>
                <!-- 公司注册证书正面照 -->
                <el-form-item prop="value6" :label="'*'+ $t('enterprise.certificateIncorporation')" v-if="isFileShow == 2 || isFileShow == 3">
                    <kycUpload v-if="enterpriseList.mediaId" :mediaId="enterpriseList.mediaId" modelCode="MKJ01ENT" catgId="CENT002"></kycUpload>
                </el-form-item>
                <!-- 法团成立表格正面照 -->
                <el-form-item prop="value6" :label="'*'+ $t('enterprise.incorporationForm')" v-if="isFileShow == 2">
                    <kycUpload v-if="enterpriseList.mediaId" :mediaId="enterpriseList.mediaId" modelCode="MKJ01ENT" catgId="CENT003"></kycUpload>
                </el-form-item>               
                <!-- 都有，非必填 -->
                <!-- 公司章程、股权架构等其他资料 -->
                <el-form-item prop="value6" :label="$t('enterprise.companyDocsArticlesOrganization')">
                    <kycUpload v-if="enterpriseList.mediaId" :limit="10" :mediaId="enterpriseList.mediaId" modelCode="MKJ01ENT" catgId="CENT004"></kycUpload>
                </el-form-item>
        </el-form>
        <!-- 退回弹框 -->
        <div class="balance-dialog">
            <el-dialog
            title=""
            :visible.sync="showDialog"
            width="400px">
                <div style="padding:0 25px;">
                    <div class="balance-dialog-icon"></div>
                    <!-- 认证失败 -->
                    <div class="balance-dialog-words">{{$t('enterprise.authenticationFailed')}}</div>
                    <div class="balance-dialog-tips">{{auditComment}}</div>
                    <!-- 重试 -->
                    <div class="submit-success-again" @click="showDialog = false"><span>{{$t('enterprise.retry')}}</span></div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import '@/styles/element.css'
import { provinceAndCityData } from 'element-china-area-data'
import kycUpload from './kycUpload'
export default {
    components: {kycUpload},
    data(){
        return{
            enterpriseId:this.$route.query.id,
            countryList:[],//国家集合
            location:'',//境内或境外
            options: [],
            selectedOptions:[],
            fixColumn:'',
            ruleForm: {
                name: '',
                region: '',
                companyName:'',
            },
            rules: {
            //     companyName: [{ required: true, trigger: 'blur' }],
            //     companyNameCn: [{ required: true, trigger: 'blur' }],
            //     registeredAreaCode: [{ required: true, trigger: 'blur' }],
            //     registeredCity: [{ required: true, trigger: 'blur' }],
            //     registeredDetail: [{ required: true, trigger: 'blur' }],
            //     companyIdentType: [{ required: true, trigger: 'blur' }],
            //     companyIdentCode: [{ required: true, trigger: 'blur' }],
            //     companyName: [{ required: true, trigger: 'blur' }],
                
            },
            enterpriseList:{
                companyNameCn:null,
                companyName:null,
                registeredArea:null,
                registeredAreaCode:null,
                registeredAreaAll:[],
                registeredProvinceCode:null,
                registeredProvince:null,
                registeredCityCode:null,
                registeredCity:null,
                registeredDetail:null,
                companyIdentType:null,
                companyIdentCode:null,               
            },
            isCityShow:false,//控制注册省市显隐
            sonLoading: false,
            isFileShow:0,//控制影像件显隐
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            isShow:false,
            isOcrShow:false,//上传影像到ocr显影
            showDialog:false,//退回弹框
            auditComment:'',//退回原因
            enterpriseObj:[],//企业证件类型
        }
    },
    computed:{
        // enterpriseObj(){
        //     let arr =  this.getSysDictValue('企业证件类型em.abd.operationCertificateTypeEnum')
        //     let filterArr = arr.filter(item=>{
        //         return  item['dictParam'] != 'ALL'
        //     })
        //     return filterArr
        // },
    },
    methods:{
        // 给input框强制刷新
        oninput(){
            this.$forceUpdate();
        },
        // 根据id查询企业信息
        async getByEnterpriseId(){
            let _this =this;
            await this.$API.enterprise.getByEnterpriseId(this.enterpriseId).then((data)=>{
                let tl = data.data.data || {}; 
                tl = this.filterObjectDict(tl);
                if(tl.mediaId){
                
                }else{
                    tl.mediaId = this.getUid();
                }
                this.$store.state.location = tl.location;
                if(tl.status == 'BACK'){
                    this.auditComment = tl.auditComment;
                    this.showDialog = true;
                }
                this.location = tl.location;
                if(tl.companyIdentCode){
                    this.isOcrShow = true;
                }
                let PromiseArr = []
                // 境内企业注册国家必须为中国
                if(tl.location == 'TERRITORY'){
                    tl.registeredAreaCode = 'CHN';
                    tl.registeredArea = '中国';                    
                    PromiseArr.push(this.changeCountry('CHN'));
                    tl.companyIdentType = 'BUSI_LICENSE';
                }else{           
                    this.isOcrShow = true;
                }
                //通过国家code判断显示影像件
                if(tl.registeredAreaCode == 'CHN'){
                    this.isFileShow = 1;
                }else if(tl.registeredAreaCode == 'HKG'){
                    this.isFileShow = 2;
                }else{
                    this.isFileShow = 3;
                }    
                this.selectedOptions = [tl.registeredProvinceCode+'0000',tl.registeredCityCode]
                Promise.all(PromiseArr).then((values)=>{
                    _this.enterpriseList = tl;
                })
            },(response)=>{})
        },
        // 查询国家表
        async getCountry(){
            this.sonLoading = true;
            await this.$API.enterprise.getCountry({}).then((data)=>{
                let tl = data.data.data;
                this.countryList = tl;
                this.sonLoading = false;
            },(response)=>{
                this.sonLoading = false;
            })
        },
        // 注册国家
        changeCountry(value){
             let country = this.countryList.filter(item=>{
                if(item.countryCode == value){
                    return item
                }
            })
            if(country.length>0){
                this.enterpriseList.registeredArea = country[0].countryName
            }
            // 判断注册省市是否为级联
            if(value == 'CHN'){
                this.isCityShow = true;
            }else{
                this.isCityShow = false;
            }
            // 判断影像件上传的显隐
            if(value == 'CHN'){
                this.isFileShow = 1;
            }else if(value == 'HKG'){
                this.isFileShow = 2;
            }else{
                this.isFileShow = 3;
            }
        },
        handleChange (value) {
            let thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
            this.enterpriseList.registeredAreaAll = value;
            this.enterpriseList.registeredProvince = thsAreaCode[0];
            this.enterpriseList.registeredCity = thsAreaCode[1];
            this.enterpriseList.registeredProvinceCode = value[0].slice(0,2);
            this.enterpriseList.registeredCityCode = value[1];
        },
        // 保存企业信息
        saveSon(){
            // this.$emit('toSave',this.enterpriseList);
            this.sonLoading = true;
            this.$API.kycFileCN.updateInPortal(this.enterpriseList).then((data)=>{
                this.sonLoading = false;
                let tl = data.data.data;
                if(data.data.code == '200'){
                    this.$message({
                        message: this.$t('login.savedSuccessfully'),
                        type: 'success'
                    });
                }
            },(response)=>{
                this.sonLoading = false;
            })
        },
        nextSon(){
            // this.$emit('toNext',this.enterpriseList);
            this.sonLoading = true;          
            this.$API.kycFileCN.handleAfterUpdateInPortal(this.enterpriseList).then((data)=>{                
                if(data.data.code == '200'){
                    this.sonLoading = false;  
                    this.$emit('toNext');
                }else{
                   this.$message.error(data.data.message);
                   this.sonLoading = false; 
                }
            },(response)=>{
                this.sonLoading = false;
            })
        },
        // 接收ocr返回的数据
        ocrEvent(obj){
            if(obj.biz_license_address){
                this.enterpriseList.registeredDetail = obj.biz_license_address || '';
                this.enterpriseList.companyIdentCode = obj.biz_license_credit_code || '';
            }else{
                this.enterpriseList.registeredDetail = '';
                this.enterpriseList.companyIdentCode = '';
            }
            this.isOcrShow = true;
           
        },
        // 语言切换后获取枚举值事件
        langChangeEvent(){
            let arr =  JSON.parse(
                sessionStorage.getItem(
                    "企业证件类型em.abd.operationCertificateTypeEnum"
                )
            );
            let filterArr = arr.filter(item=>{
                return  item['dictParam'] != 'ALL'
            })
            this.enterpriseObj = filterArr;
        },
    },
    async mounted(){
        await this.getByEnterpriseId();
        await this.getCountry();
        // 获取企业证件类型枚举
        let arr =  JSON.parse(
            sessionStorage.getItem(
                "企业证件类型em.abd.operationCertificateTypeEnum"
            )
        );
        let filterArr = arr.filter(item=>{
            return  item['dictParam'] != 'ALL'
        })
        this.enterpriseObj = filterArr;
    },
    created(){
        let tl = provinceAndCityData;
        tl.map(item=>{            
            if(item.value == "110000" && item.label == "北京市"){
                item.children[0].value = "110000"
                item.value = "110001"
            }else if(item.value == "120000" && item.label == "天津市"){
                item.children[0].value = "120000"
                item.value = "120001"
            }else if(item.value == "310000" && item.label == "上海市"){
                item.children[0].value = "310000"
                item.value = "310001"
            }else if(item.value == "500000" && item.label == "重庆市"){
                item.children[0].value = "500000"
                item.value = "500001"
            }
        })
        this.options = tl;
    }
}
</script>