<style lang="scss" scoped>
.enterprise{
    padding: 0 30px;
    height: 525px;
    overflow: auto;        
}
.content-right-title{
    
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #4e78b1;
    margin-top: 50px;
}
.content-right-basics{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-basics span{
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #99a3b1;
}
.content-right-upload{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-upload span{
    
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #99a3b1;
}
.content-right-line{
    width: 100%;
    height: 2px;
    background-color: #f3f8ff;
    margin-bottom: 20px;
}
.content-right-radio{
    margin-right: 40px;
}
.content-right-all{
    display: flex;   
    margin-right: 40px;
}
.content-right-sel{
    padding: 0 5px;
}
.content-right-tips{
    
    font-size: 12px;
    transform: scale(0.9);
    transform-origin: left;
    font-weight: normal;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 25px;
    margin-top: 5px;
}
.beneficiary-list{
    display: flex;
    margin-bottom: 20px;
}
.beneficiary-list-li{
    height: 32px;
    font-size: 12px;
    color: #4a4a4a;
    border-radius: 20px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #a6cafb;
    background-color: #f4f9ff;
    margin-right: 15px;
    padding: 0 10px;
}
.beneficiary-list-add{
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    color: #5a9cf8;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border: 1px dashed #d9e8fe;
    border-radius: 20px;
    margin-right: 10px;
    padding: 0 10px;
}
.upload-icon{
    width: 23px;
    height:27px;
    background:url("~@/static/img/uploadIcon.svg") center center no-repeat;
    margin-right: 5px;
}
</style>

<template>
    <div class="enterprise" v-bLoading="loading">
        <!-- 受益人信息 -->
        <div class="content-right-title">{{$t('enterprise.whoBeneficialOwner')}}</div>
        <!-- 受益人是：对企业的资产拥有25%或以上直接或间接股权关系的自然人，或 持有在股东大会上25%或以上表决权的自然人。 -->
        <div class="content-right-tips">{{$t('enterprise.BONaturalPerson')}}</div>
        <div v-for="(table,index) in beneficiaryList" :key="index">
            <div class="beneficiary-list">  
                         <!-- 受益人 -->
                <div class="beneficiary-list-li"><span>{{$t('enterprise.beneficialOwner')}}<i>{{index+1}}</i></span></div>
                <!-- 删除受益人 -->
                <div class="beneficiary-list-add" v-if="index != 0" @click="delBeneficiary(index)"><span>- {{$t('enterprise.deleteBeneficiaryOwner')}}</span></div>
                <div class="beneficiary-list-add" @click="addBeneficiary()" v-if="index+1 == beneficiaryList.length"><span>+ {{$t('enterprise.addBeneficialOwner')}}</span></div>                
            </div>
            <el-form :model="ruleForm" :inline="true" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <div style="display: flex;justify-content: space-between;">
                    <!-- 基本信息 -->
                    <div class="content-right-basics">{{$t('enterprise.companyInfo')}}</div>
                    <!-- <div class="content-right-radio">
                        <el-checkbox v-model="table.equalsContact" label="与法人信息相同" @change="changeInfo(table,index)"></el-checkbox>
                    </div> -->
                </div>
                <!-- <div style="display: flex;justify-content: space-between;line-height: 40px;" v-else>
                    <div class="content-right-basics">基本信息</div>
                    <div class="content-right-all">
                        <el-checkbox label=""></el-checkbox>
                        <span>&nbsp;与</span>
                        <div class="content-right-sel">
                            <el-select v-model="value" clearable placeholder="请选择">
                                <el-option
                                v-for="item in legalList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <span>信息相同</span>
                    </div>
                </div> -->
                <el-form-item :label="'*'+ $t('common.name')" prop="name">
                    <el-input v-model="table.name" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.positionCompany')" prop="name">
                    <el-input v-model="table.job" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.email')" prop="email">
                    <el-input v-model="table.email" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.phoneNo')" prop="phone">
                    <el-input v-model="table.phone" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.IDType')" prop="region">
                    <el-select v-model="table.identType" :inline="true" :placeholder="$t('common.plSelect')">
                        <el-option  v-for="(item, index) in credentialsObj" :key="index" :label="item.dispalyName" :value="item.dictParam"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('common.IDNo')" prop="phone">
                    <el-input v-model="table.identNo" width="300px"></el-input>
                </el-form-item>
                <!-- 上传文件 -->
                <div class="content-right-basics">{{$t('common.documents')}} <span>{{$t('common.pleaseUploadClear')}}</span></div>
                <el-form-item prop="value6" :label="$t('common.IDDocFront')">
                    <kycUpload v-if="table.mediaId" @toOcr="ocrEvent" :index="index" :type="table.identType" :mediaId="table.mediaId" modelCode="MKJ02CONTACT" catgId="CCONTACT001"></kycUpload>
                </el-form-item>
                <el-form-item prop="value6" :label="$t('common.IDDocBack')">
                    <kycUpload v-if="table.mediaId" @toOcr="ocrEvent" :type="table.identType" :mediaId="table.mediaId" modelCode="MKJ02CONTACT" catgId="CCONTACT002"></kycUpload>
                </el-form-item>
                <div class="content-right-line"></div>
            </el-form>
        </div>
        
    </div>
</template>

<script>
import '@/styles/element.css'
import kycUpload from './kycUpload'
export default {
    components: {kycUpload},
    data(){
        return{
            enterpriseId:this.$route.query.id,
            location:this.$store.state.location || '',//境内或境外
            addShow:false,
            beneficiaryName:'',
            beneficiaryList:[{
                name:null,
                job:null,
                email:null,
                phone:null,
                identType:null,
                identNo:null,
                mediaId:null,
                enterpriseId:this.$route.query.id,
                equalsContact:false,
            }],
            isLegal:false,//与法人或董事相同的显隐
            legalList:[],//法人或董事的集合
            loading:false,
            fixColumn:'',
            radio:'0',
            ruleForm: {
                name: '',
                region: '',
                companyName:'',
            },
            rules: {
                name: [
                    // { required: true, message: '请输入活动名称', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    // { required: true, message: '请选择活动区域', trigger: 'change' }
                ],
            },
            options: [{
                value: '选项1',
                label: '黄金糕'
                }, {
                value: '选项2',
                label: '双皮奶'
                }, {
                value: '选项3',
                label: '蚵仔煎'
                }, {
                value: '选项4',
                label: '龙须面'
                }, {
                value: '选项5',
                label: '北京烤鸭'
                }],
            value: '',
            credentialsObj:[],//证件类型
        }
    },
    computed:{
        // credentialsObj(){
        //     let arr =  this.getSysDictValue('证件类型em.ComStepStatus')
        //     let filterArr = arr.filter(item=>{
        //         return  item['dictParam'] != 'ALL'
        //     })
        //     return filterArr
        // },
    },
    methods:{
        addBeneficiary(){
            this.beneficiaryList.push({
                name:null,
                job:null,
                email:null,
                phone:null,
                identType:null,
                identNo:null,
                mediaId:this.getUid(),
                enterpriseId:this.enterpriseId,
            });
        },
        delBeneficiary(index){
            this.beneficiaryList.splice(index,1);
        },
        // 根据id查询受益人信息
        listBeneficiaryByEnterpriseId(){
            this.$API.beneficiary.listBeneficiaryByEnterpriseId(this.enterpriseId).then((data)=>{
                let tl = data.data.data || [];
                tl = this.filterArrayDict(tl);
                if(tl.length == 0){
                    this.beneficiaryList[0]['mediaId'] = this.getUid();
                }else{
                    tl.map(item=>{
                        item.enterpriseId = this.enterpriseId;
                        if(item.mediaId){

                        }else{
                            item.mediaId = this.getUid();
                        }
                    })
                    this.beneficiaryList = tl;
                }

            },(response)=>{})
        },
        // 保存受益人信息
        saveSon(){
            // this.$emit('toSave',this.beneficiaryList);
            this.loading =true;
            this.$API.kycFileCN.updateBeneficiary(this.beneficiaryList).then((data)=>{
                this.loading = false;
                let tl = data.data.data;
                if(data.data.code == '200'){
                    this.$message({
                        message: this.$t('login.savedSuccessfully'),
                        type: 'success'
                    });
                }
            },(response)=>{
                this.loading = false;
            })
        },
        nextSon(){
            // this.$emit('toNext',this.beneficiaryList);
            this.loading = true;          
            this.$API.kycFileCN.handleAfterUpdateBeneficiaryInPortal(this.beneficiaryList).then((data)=>{                
                if(data.data.code == '200'){
                    this.loading = false;
                    this.$emit('toNext');
                }else{
                   this.$message.error(data.data.message);
                   this.loading = false; 
                }
            },(response)=>{
                this.loading = false;
            }) 
        },
        // 根据id查询法人或董事
        // listLegalByEnterpriseId(table,index){
        //     this.loading =true;
        //     this.$API.legalPerson.listLegalByEnterpriseId(this.enterpriseId).then((data)=>{
        //         // this.beneficiaryList = [];
        //         let tl = data.data.data || [];
        //         tl = this.filterArrayDict(tl);
        //         if(tl.length == 0){
                    
        //         }else if(tl.length == 1){
        //             this.beneficiaryList[index]['name'] = tl[0]['name'] || '';
        //             this.beneficiaryList[index]['position'] = tl[0]['position'] || '';
        //             this.beneficiaryList[index]['email'] = tl[0]['email'] || '';
        //             this.beneficiaryList[index]['phone'] = tl[0]['phone'] || '';
        //             this.beneficiaryList[index]['identType'] = tl[0]['identType'] || '';
        //             this.beneficiaryList[index]['identNo'] = tl[0]['identNo'] || '';
        //             this.loading =false;
        //         }else{
        //             this.beneficiaryList[index] = {};
        //             this.beneficiaryList[index] = tl[index] || {};
        //             this.loading =false;
        //         }         
        //     },(response)=>{
        //         this.loading =false;
        //     })
        // },
        listLegalByEnterpriseId(){
            this.loading =true;
            this.$API.legalPerson.listLegalByEnterpriseId(this.enterpriseId).then((data)=>{
                // this.beneficiaryList = [];
                let tl = data.data.data || [];
                tl = this.filterArrayDict(tl);
                // 先接收查询法人或董事的参数
                this.legalList = tl;
                this.loading =false;         
            },(response)=>{
                this.loading =false;
            })
        },
        // 与法人或董事信息相同
        changeInfo(table,index){
            // this.listLegalByEnterpriseId(table,index);
        },
        // 接收ocr返回的数据
        ocrEvent(obj){
            let index = obj.index;           
            if(obj.id_number){
                this.beneficiaryList[index]['identNo'] = obj.id_number || '';
            }          
        },
    },
    async mounted(){
        await this.listLegalByEnterpriseId();
        this.listBeneficiaryByEnterpriseId();
        if(this.$store.state.location == 'TERRITORY'){
            this.isLegal = true;
        }else{
            this.isLegal = false;
        }
        // 获取证件类型枚举
        this.credentialsObj = JSON.parse(
            sessionStorage.getItem(
                "证件类型em.ComStepStatus"
            )
        );
    },
}
</script>