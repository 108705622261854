import { render, staticRenderFns } from "./ekycPortal.vue?vue&type=template&id=7333de31&scoped=true&"
import script from "./ekycPortal.vue?vue&type=script&lang=js&"
export * from "./ekycPortal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7333de31",
  null
  
)

export default component.exports