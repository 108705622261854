import {
  registerMicroApps,
  start,
  prefetchApps,
  initGlobalState,
  MicroAppStateActions,
} from 'qiankun'
import Vue from 'vue'
import App from './App'
import router from './routes'

import '@babel/polyfill'

import './styles/reset.css'
import './styles/common.css'
//代码自动化生成页面需要用到的样式
import './styles/auto/sear.css'
import './styles/auto/form.css'
import './styles/auto/wkfl.css'
//自定义公共过滤器，指令，方法和插件
import './common/filter.js'
import './common/directive.js'
import './common/prototype.js'
import './common/plug.js'
//引用全局变量文件
import globalVariable from '@/common/global_variable.js'
//接口请求配置文件
// import './https/https.js'
import httpsAxios from './https/https_lls'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//引入vuex
import store from './store/index'
//引入国际化语音
import i18n from './i18n/index'
//引入接口管理
import API from '@/api/index.js'
Vue.prototype.$API = API
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) })

Vue.prototype.GLOBAL = globalVariable
Vue.prototype.choosedCompPortal = {}

const getActiveRule = hash => location => location.hash.startsWith(hash)
httpsAxios().finally(() => {
  // debugger
  window.vm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
  window.vm.$store.state.projectFinishFlag = true
})

prefetchApps([
  {
    name: 'abtPortal',
    entry:
      process.env.NODE_ENV === 'production'
        ? '/abtPortal/'
        : '//localhost:8991',
  },
  {
    name: 'custodyPortal',
    entry:
      process.env.NODE_ENV === 'production'
        ? '/custodyPortal/'
        : '//localhost:8990',
  },
])
const state = {
  eventName: '', //事件名称
  shop: '', // 店铺
  curPagePath: '',
  langlocale: localStorage.getItem('locale') || 'en',
  childLocale: localStorage.getItem('childLocale') || 'en',
  iddLocal: localStorage.getItem('iddLocal') || 'en', //控制idd子应用的语言
  efinanceLocal: localStorage.getItem('efinanceLocal') || 'en', //控制efinace子应用的语言
  enterprise: {},
  cartNum: 0,
}
const { onGlobalStateChange, setGlobalState } = initGlobalState(state)
onGlobalStateChange((state, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  if (prev.curPagePath != state.curPagePath) {
    window.vm.$emit('childRouteChange', state.curPagePath)
  }
  if (prev.langlocale != state.langlocale) {
    localStorage.setItem('locale', state.langlocale)
    window.vm.$i18n.locale = state.langlocale
    window.vm.$emit('langChange')
  }
  if (prev.childLocale != state.childLocale) {
    localStorage.setItem('childLocale', state.childLocale)
    window.vm.$emit('childLangChange')
  }
  if (state.eventName == 'changeEnterprise') {
    window.vm.$emit('changeEnterprise', state.enterprise)
  }
  if (state.eventName == 'addCart') {
    window.vm.$emit('addCart', state.cartNum)
  }
})
Vue.prototype.$setGlobalState = setGlobalState
